<template>
  <div class="content">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">{{ $t('intelligentSecurity.title') }}</div>
    </div>

    <!-- list -->
    <div class="control">
      <div
        class="control-item"
        v-for="item in listArr"
        :key="item.id"
        @click="handleControl(item.id)"
      >
        <div class="left">
          <img class="icon" :src="item.controlImg" alt="" />
          <p>{{ item.controlP }}</p>
        </div>
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/arrow-right.png"
          alt=""
          class="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import request from "@/utils/request";
import Alarmlamp from '../../assets/securityVehicle/alarm-lamp.png';
import Container from '../../assets/securityVehicle/container.png';
import Voice from '../../assets/securityVehicle/voice.png';
import Volume from '../../assets/securityVehicle/volume.png';
import SideScreen from '../../assets/securityVehicle/side-screen.png';

export default {
  name: 'SecurityVehicle',
  components: {},
  data() {
    return {
      listArr: [],
      cabinetType: '',
      VinType: '',
      vin: '',
      volume: '',
    };
  },

  created: function () {
    this.cabinetType = this.$route.query.cabinetType;
    this.VinType = this.$route.query.VinType;
    this.vin = this.$route.query.vin;
    // volume新车会上报-1
    this.volume = this.$route.query.volume
      ? Number(this.$route.query.volume) === -1
        ? 100
        : Number(this.$route.query.volume)
      : 50;
  },

  mounted: function () {
    this.setLanguage(this.$route.query.language || window.localStorage.getItem('language'));
    this.listArr = [
      {
        id: 1,
        controlImg: Alarmlamp,
        controlP: this.$t('intelligentSecurity.listArr1'),
      },
      {
        id: 2,
        controlImg: Container,
        controlP: this.$t('intelligentSecurity.listArr2'),
      },
      {
        id: 5,
        controlImg: SideScreen,
        controlP: this.$t('intelligentSecurity.listArr3'),
      },
      {
        id: 3,
        controlImg: Voice,
        controlP: this.$t('intelligentSecurity.listArr4'),
      },
      {
        id: 4,
        controlImg: Volume,
        controlP: this.$t('intelligentSecurity.listArr5'),
      },
    ];
  },

  methods: {
    setLanguage(language = 'zh') {
      this.$i18n.locale = language;
    },
    // 返回
    goback() {
      window.location.href = 'neolix://interaction';
    },

    // 点击list
    handleControl(id) {
      if (id === 4) {
        this.$router.push({
          path: '/voice',
          query: {
            id,
            vin: this.vin,
            volume: this.volume,
            cabinetType: this.cabinetType,
            VinType: this.VinType,
            language: this.$route.query.language,
          },
        });
      } else {
        this.$router.push({
          path: '/controlSecurity',
          query: { id, vin: this.vin, language: this.$route.query.language },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  font-size: initial;
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .back {
      width: 32px;
      height: 32px;
      margin: 4px 11px;
    }
    .title {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
      text-align: center;
      width: 100%;
      min-width: 100px;
      margin-right: 54px;
    }
  }
  .control {
    margin-top: 60px;
    .control-item {
      width: 90%;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      box-sizing: border-box;
      opacity: 1;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      margin: 0 auto;
      margin-bottom: 16px;
      &:active {
        background: rgba(235, 238, 239, 1);
      }
      .left {
        display: flex;
        align-items: center;
        .icon {
          width: 56px;
          height: 56px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .right {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
